import React from 'react'
import { Link } from '@reach/router'

import me from '../../static/bio.jpg'
import dimo from '../../content/gambar/1624424892087.jpg'

export const AboutSidebar = () => {
  return (
    <aside className="post-sidebar">
      <div className="post-sidebar-card">
        
        <img src={me} alt="Aksara" />
      </div>
    </aside>
  )
}
